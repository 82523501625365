.scroll{
    background: linear-gradient(to right, hsl(0, 0%, 20%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 20%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 4s infinite linear;
}


@keyframes shine {
    0% {
        background-position: 0;
    }
    60% {
        background-position: 1000px;
    }
    100% {
        background-position: 2000px;
    }
}